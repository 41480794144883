import React from "react";
import styles from "../../Modals/Modal/Modal.module.scss";
import stylesCapture from "../../Modals/EmailCaptureModal/EmailCaptureModal.module.scss";
import { useModalResourceDownloadBuilder } from "@sp-state/modals/useModalResourceBuilder";
import { PrimaryButton } from "@components/Buttons";

type ModalResourceType = {
  handleDownload: () => void;
};

const ModalSetDownload: React.FC<ModalResourceType> = ({ handleDownload }) => {
  const { modalOpen, setModalResourceDownloadBuilder } =
    useModalResourceDownloadBuilder(state => state);

  const handleClose = () => {
    setModalResourceDownloadBuilder(false);
  };

  return (
    <div
      data-testid="modal"
      className={`${styles.modalContainer} ${
        modalOpen && styles.modalContainerActive
      }`}
    >
      <div
        className={`${styles.modalContent}  ${
          modalOpen && styles.modalContentActive
        }`}
      >
        <div className={styles.modalBody}>
          <h5
            data-testid="modal-header"
            className={stylesCapture.modalBodyTitle}
          >
            You’re all set!
          </h5>
          <p
            data-testid="modal-subheader"
            className={stylesCapture.modalBodySubtitle}
          >
            Your download should start automatically. If not,{" "}
            <button
              onClick={handleDownload}
              className={stylesCapture.downloadFileLink}
            >
              download the file
            </button>{" "}
            here.
          </p>
          <PrimaryButton
            buttonText="Close"
            onClickHandler={handleClose}
            externalClassName={styles.closeBtnBlue}
          />
          <button
            type="button"
            onClick={handleClose}
            className={styles.closeBtn}
          >
            <img src="/icons/close.svg" alt="Close Icon" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalSetDownload;
