import { create } from "zustand";

export const useModalResourceBuilder = create<Modals.UseModalResourceBuilder>(
  set => ({
    modalOpen: false,
    setModalResourceBuilder: val => set({ modalOpen: val }),
  })
);

export const useModalResourceDownloadBuilder =
  create<Modals.UseModalResourceDownloadBuilder>(set => ({
    modalOpen: false,
    setModalResourceDownloadBuilder: val => set({ modalOpen: val }),
  }));
