import React, { useCallback } from "react";
import { Builder } from "@builder.io/react";
import styles from "./ResourceGatedDownload.module.scss";
import { PrimaryButton } from "@components/Buttons";
import ModalResourceBuilder from "@components/BuilderComponents/ModalResourceDownload";
import { Cookie } from "@utils/Cookie";
import { mxpResourceDownload } from "@utils/mixpanelEvents/mixpanelEvents";
import {
  useModalResourceBuilder,
  useModalResourceDownloadBuilder,
} from "@sp-state/modals/useModalResourceBuilder";
import ModalSetDownload from "@components/BuilderComponents/ModalResourceDownload/ModalSetDownload";
import { isAppleFamilyDevices } from "@utils/validations";
import useWindowSize from "@hooks/useWindowSize";
import { resourceDownload } from "@utils/segment/resourceDownload";

type ResourceGatedType = {
  ctaText?: string;
  downloadTitle: string;
  modalHeadline: string;
  download: {
    value: {
      data: Queries.builder_DownloadsData;
    };
  };
};

const ResourceGatedDownload: React.FC<ResourceGatedType> = ({
  ctaText,
  downloadTitle,
  modalHeadline,
  download,
}) => {
  const { setModalResourceBuilder } = useModalResourceBuilder(state => state);
  const setModalResourceDownloadBuilder = useModalResourceDownloadBuilder(
    state => state.setModalResourceDownloadBuilder
  );

  const isAppleDevice = isAppleFamilyDevices();
  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth < 1024 ? true : false;

  const ctaLink = download?.value?.data?.fileUpload;
  const fileName = download?.value?.data?.fileName || "file";

  const handleDownload = useCallback(() => {
    if (!ctaLink) return;

    if (isAppleDevice && isMobile) {
      return window.open(ctaLink, "_blank");
    }

    fetch(ctaLink)
      .then(res => {
        return res.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        setTimeout(() => {
          a.click();
          a.remove();
        }, 200);
      })
      .catch(err => {
        return window.open(ctaLink, "_blank");
      });
  }, [ctaLink, fileName, isAppleDevice, isMobile]);

  const handleOnClick = () => {
    const spLastAccess = Cookie.getCookie("sp_last_access");
    const spResource = Cookie.getCookie("sp_resource");

    if ((spLastAccess || spResource) && ctaLink) {
      mxpResourceDownload(spResource, spLastAccess, ctaText);
      resourceDownload(ctaText, fileName);

      setModalResourceDownloadBuilder(true);

      return handleDownload();
    }

    setModalResourceBuilder(true);
  };

  return (
    <>
      <div className={styles.containerGated}>
        <div className={styles.imageText}>
          <img src="/icons/paper.svg" alt="Paper Icon" />
          <div className={styles.textGated}>
            <h3>{downloadTitle}</h3>
          </div>
        </div>
        <PrimaryButton
          buttonText={ctaText}
          data-click-text={ctaText}
          key={"var-0"}
          externalClassName={styles.button}
          onClickHandler={handleOnClick}
        />
      </div>
      <ModalResourceBuilder
        ctaText="Download"
        header={modalHeadline}
        ctaLink={ctaLink}
        ctaId="resource-gated"
        subcopy="By entering your email address, you are opting-in to receive emails from SimplePractice on its various products, solutions, and/or offerings. Unsubscribe anytime."
        subheaderCopy="Enter your email below to access this resource."
        handleDownload={handleDownload}
        fileName={fileName}
      />
      <ModalSetDownload handleDownload={handleDownload} />
    </>
  );
};

export default ResourceGatedDownload;

export const RegisterResourceGatedDownload = () => {
  Builder.registerComponent(ResourceGatedDownload, {
    name: "resource-download-gated",
    inputs: [
      {
        name: "download",
        type: "reference",
        model: "downloads",
      },
      {
        name: "downloadTitle",
        type: "text",
        defaultValue: "Lorem ipsum dolor sit amet",
      },
      { name: "ctaText", type: "text", defaultValue: "Download now" },
      {
        name: "modalHeadline",
        type: "text",
        defaultValue: "Download free resource",
      },
    ],
  });
};
