import React from "react";
import { BuilderComponent } from "@builder.io/react";
import { RegisterComponentLibrary } from "@utils/RegisterComponentLibrary";

/* LINK  https://www.builder.io/c/docs/integrate-symbols */

function SymbolEditingPage() {
  return <BuilderComponent model="symbol" />;
}

export default SymbolEditingPage;

export const Head = () => {
  return <meta name="robots" content="noindex,nofollow"></meta>;
};

RegisterComponentLibrary();
