import React from "react";

interface IResourceDownloadEmailContext {
  hasError: boolean;
  setHasError: (value: boolean) => void;
  email: string;
  setEmail: (value: string) => void;
}

export const formInitialCtx: IResourceDownloadEmailContext = {
  hasError: false,
  setHasError: () => {},
  email: "",
  setEmail: () => {},
};

export const ResourceDownloadEmailContext = React.createContext(formInitialCtx);
